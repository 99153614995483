<template>
  <div class="arlist mx-3">
    <h3 class="mt-0 mb-4">Annual Report Review</h3>
    <div v-if="!ar_data" class="mt-3">
      <b-card>
        <b-container class="pr-0 mb-3 mx-0 px-0">
          <b-row class="text-left">
            <b-col>
              <b-form-group label="Annual Report Year">
              <b-form-select v-model="report_year" :options="report_years" @change="fetchARList(); onReportYearSelect();">
              </b-form-select>
              </b-form-group>
          </b-col>
            <b-col>
              <b-form-group
                label-for="filter-status-value"
                label="Filter Status"
              >
                <b-form-select
                  id="filter-status-value"
                  v-model="filter_status_value"
                  :options="filter_status_options"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row class="text-left" align-v="center">
            <b-col>
              <b-form-group label-for="pws-id-value" label="Filter PWS ID">
                <b-form-input id="pws-id-value" v-model="filter_pws_id_value">
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col class="text-right">
              <b-button variant="primary" @click="fetchARList">
                <span v-if="table_is_busy">
                  <b-spinner
                    small
                    v-if="table_is_busy"
                    variant="light"
                    label="Loading"
                  >
                  </b-spinner>
                  Reloading
                </span>
                <span v-if="!table_is_busy">Reload Data</span>
              </b-button>
              <span> &nbsp; {{ ar_count }} results </span>
            </b-col>
          </b-row>
        </b-container>
        <b-pagination
          v-model="current_page"
          :total-rows="ar_count"
          :per-page="per_page"
        >
        </b-pagination>
        <b-table
          :filter-included-fields="filter_included_fields"
          :filter="filter_value"
          primary-key="id"
          striped
          hover
          :busy.sync="table_is_busy"
          :items="ar_list"
          :fields="fields"
          :per-page="per_page"
          :current-page="current_page"
        >
          <template #cell(pws_id)="data">
            <b-button @click="setReport(data.item.id)">{{
              data.value
            }}</b-button>
          </template>
        </b-table>
      </b-card>
    </div>

    <div v-if="ar_data" class="text-left m-4">
      <b-button @click="backToList" class="mb-3">Back to List</b-button>
      <b-card title="Annual Report Review" class="mb-3">
        <b-card-text>
          <strong>{{ ar_data["pws_id"] }}, {{ new_data["pws_name"] }} </strong>
          <br />
          <strong>Processed Timestamp: </strong> {{ process_ts }} 
        </b-card-text>
        <b-form-group label-for="report-status" label="Report Status">
          <b-form-select
            id="report-status"
            v-model="review_status"
            :options="form_status_options"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="reviewer-name" label="Reviewer Name">
          <b-form-input
            id="reviewer-name"
            v-model="reviewer_name"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-for="review-comments" label="Review Comments">
          <b-form-textarea
            id="review-comments"
            v-model="review_comments"
            rows="3"
          ></b-form-textarea>
        </b-form-group>
        <b-button @click="saveARReview" variant="primary">Save Review</b-button>
        <b-alert class="mt-3" variant="success" :show="data_saved" dismissible
          >Data Saved</b-alert
        >
        <b-alert
          class="mt-3"
          variant="error"
          :show="data_saved_error"
          dismissible
          >Error Saving Data</b-alert
        >
      </b-card>
      <b-button @click="showBoardMembers" class="my-3">
        {{ show_board_members ? "Hide" : "Show" }} Board Members
      </b-button>

      <div v-if="show_board_members">
        
        <p><b>Board Member Exception:</b> &nbsp;
        <span v-if="ar_data.board_member_exception" class="text-success">Yes</span>
        <span v-else>No</span>
        </p>

        <b-form-group
          label-for="bm-review-status"
          label="Board Member Review Status"
        >
          <b-form-select
            id="bm-review-status"
            v-model="bm_review_status"
            :options="bm_review_status_options"
          >
          </b-form-select>
        </b-form-group>

        <b-table
          :items="board_members"
          :fields="bm_fields"
          striped
          hover
          class="mb-3"
        >
        </b-table>

      </div>

      <h3>General PWS Info</h3>
      <b-table
        striped
        hover
        :fields="ar_data_fields"
        :items="ar_data.data['General']"
        :tbody-tr-class="rowClass"
      >
        <template #cell(ar_value)="data">
          {{ data.item.ar_value }}
          <b-badge v-if="data.item.fieldname === 'population' && data.item.ar_value < 25" variant="danger">Value below 25</b-badge>
        </template>
        <template #cell(new_value)="data">
          <b-form-input v-model="new_data[data.item.fieldname]"></b-form-input>
        </template>
      </b-table>

      <h3>Administrative Contact (Office Manager)</h3>
      <b-table
        striped
        hover
        :fields="ar_data_fields"
        :items="ar_data.data['AC']"
        :tbody-tr-class="rowClass"
      >
        <template #cell(new_value)="data">
          <b-form-input v-model="new_data[data.item.fieldname]"></b-form-input>
        </template>
      </b-table>

      <h3>Legally Responsible Official</h3>
      <b-table
        striped
        hover
        :fields="ar_data_fields"
        :items="ar_data.data['LRO']"
        :tbody-tr-class="rowClass"
      >
        <template #cell(new_value)="data">
          <b-form-input v-model="new_data[data.item.fieldname]"></b-form-input>
        </template>
      </b-table>

      <h3>Designated Operator</h3>
      <b-table
        striped
        hover
        :fields="ar_data_fields"
        :items="ar_data.data['DO']"
        :tbody-tr-class="rowClass"
      >
        <template #cell(new_value)="data">
          <b-form-input v-model="new_data[data.item.fieldname]"></b-form-input>
        </template>
      </b-table>

      <!--
      <ul>
        <li v-for="(value, name, index) in ar_data" :key="index">
          {{ name }} {{ value }} {{ index }}
        </li>
      </ul>
      -->
      <b-button @click="backToList">Back to List</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AnnualReportList",
  created() {
    // figure out the report year
    const ls_report_year = localStorage.getItem('ar_report_year')
    if (ls_report_year) {
      console.debug('got saved ar year', ls_report_year)
      this.report_year = parseInt(ls_report_year)
    }
    this.fetchARList()
    // add years to select
    const ymax = new Date().getFullYear() + 1
    const ymin = 2021
    let y = ymin
    while (y <= ymax) {
      this.report_years.push(y)
      y = y + 1
    }
  },
  data() {
    return {
      report_id: null,
      report_years: [],
      report_year: new Date().getFullYear(),
      ar_list: [],
      ar_data: null,
      board_members: [],
      show_board_members: false,
      review_id: null,
      reviewer_name: "Anonymous",
      review_status: null,
      review_comments: null,
      process_ts: '',
      new_data: {},
      data_saved: false,
      data_saved_error: false,
      form_status_options: [
        { value: "N", text: "Not Reviewed" },
        { value: "R", text: "Reviewed" },
        { value: "F", text: "Followup Required" },
        { value: "E", text: "Edit Required" }
      ],
      ar_data_fields: [
        { key: "fieldname", label: "Field Name" },
        { key: "ar_value", label: "Reported Value" },
        { key: "ss_value", label: "SDWIS Value" },
        { key: "new_value", label: "New Value" }
      ],
      filter_pws_id_value: "",
      filter_status_value: "",
      filter_status_options: [
        { value: "", text: "Show All" },
        { value: "N", text: "Not Reviewed" },
        { value: "R", text: "Reviewed" },
        { value: "F", text: "Followup Required" },
        { value: "E", text: "Edit Required" }
      ],
      table_is_busy: false,
      per_page: 10,
      current_page: 1,
      current_report_id: null,
      bm_fields: [
        "name_first",
        "name_last",
        "street",
        "city",
        "state",
        "zipcode",
        "phone",
        "email",
        "training_date",
        "advanced_training_date",
        "term_begin_date",
        "term_end_date"
      ],
      bm_review_status: "N",
      bm_review_status_options: [
        { value: "N", text: "Not Reviewed" },
        { value: "A", text: "Reviewed" },
        { value: "F", text: "Followup Required" },
        { value: "R", text: "Rejected" },
      ],
      fields: [
        { key: "pws_id", label: "PWS ID", sortable: true },
        { key: "pws_name", label: "PWS Name", sortable: true },
        { key: "pws_type", label: "PWS Type", sortable: true },
        { key: "data_signame", label: "Signer", sortable: true },
        { key: "data_signature_ts", label: "DateTime Signed", sortable: true },
        { key: "process_ts", label: "DT Processed", sortable: true },
        { key: "status", label: "Status", sortable: true }
      ]
    }
  },
  methods: {
    onReportYearSelect() {
      console.debug('selected report year', this.report_year)
      localStorage.setItem('ar_report_year', this.report_year)
    },
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (item.diff === true) return "table-warning"
    },
    fetchARList() {
      this.table_is_busy = true
      const path = process.env.VUE_APP_API_URI + "/annual-report-list"
      console.log(path)
      this.axios
        .get(path, { params: { report_year: this.report_year } })
        .then((res) => {
          if (res.data) {
            // save dataset
            const ar_list = []
            res.data.forEach(function(ar_item) {
              ar_item.data_signature_ts = ar_item.data_signature_ts.replace(
                "T",
                " "
              )
              if (ar_item.process_ts) {
                ar_item.process_ts = ar_item.process_ts.replace("T"," ")
              }
              ar_list.push(ar_item)
            })
            this.ar_list = ar_list
            this.table_is_busy = false
          }
        })
        .catch((error) => {
          this.table_is_busy = false
          console.error(error)
        })
    },
    fetchBoardMembers() {
      if (this.report_id && this.board_members.length == 0) {
        const path =
          process.env.VUE_APP_API_URI + "/annual-report-board-members"
        console.log(path)
        this.axios
          .get(path, { params: { report_id: this.report_id } })
          .then((res) => {
            if (res.data) {
              this.board_members = res.data
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    showBoardMembers() {
      if (this.show_board_members) {
        this.show_board_members = false
      } else {
        this.show_board_members = true
        this.fetchBoardMembers()
      }
    },
    fetchARData(report_id) {
      // reset data saved
      this.show_board_members = false
      this.board_members = []
      this.data_saved = false
      this.data_saved_error = false
      this.table_is_busy = true
      const path = process.env.VUE_APP_API_URI + "/annual-report-diff"
      console.log(path)
      this.axios
        .get(path, { params: { report_id: report_id } })
        .then((res) => {
          if (res.data) {
            this.ar_data = res.data
            // populate new data fields
            this.review_status = this.ar_data.status
            this.bm_review_status = this.ar_data.board_member_status
            this.review_id = this.ar_data.review_id
            this.review_comments = this.ar_data.comments
            this.process_ts = this.ar_data.process_ts || "Not processed."
            for (const category in this.ar_data.data) {
              const cat_rows = this.ar_data.data[category]
              for (const cat_row_id in cat_rows) {
                const cat_row = cat_rows[cat_row_id]
                const fieldname = cat_rows[cat_row_id].fieldname
                if (this.review_status !== 'N') {
                  this.new_data[fieldname] = cat_row.new_value
                } else if (cat_row.diff) {
                  this.new_data[fieldname] = cat_row.ar_value
                } else {
                  this.new_data[fieldname] = cat_row.ss_value
                }
              }
            }
            this.table_is_busy = false
          }
        })
        .catch((error) => {
          this.table_is_busy = false
          console.error(error)
        })
    },
    saveARReview() {
      //pass
      this.data_saved = false
      this.data_saved_error = false
      const path = process.env.VUE_APP_API_URI + "/save-annual-report-review"
      console.log("saving " + this.review_id)
      this.axios
        .post(path, {
          review_id: this.review_id,
          reviewer_name: this.reviewer_name,
          review_data: this.new_data,
          review_comments: this.review_comments,
          review_status: this.review_status,
          board_member_status: this.bm_review_status
        })
        .then((res) => {
          console.log(res)
          this.data_saved = 3
        })
        .catch((error) => {
          console.error(error)
          this.data_saved_error = 3
        })
    },
    backToList() {
      this.report_id = null
      this.ar_data = null
      if (this.data_saved) {
        this.fetchARList()
      }
    },
    setReport(report_id) {
      this.report_id = report_id
      console.log("setting report " + report_id)
      this.fetchARData(report_id)
    }
  },
  computed: {
    ar_count: function() {
      return this.ar_list.length
    },
    filter_value: function() {
      if (this.filter_pws_id_value) {
        return this.filter_pws_id_value
      } else {
        return this.filter_status_value
      }
    },
    filter_included_fields: function() {
      if (this.filter_pws_id_value) {
        return ["pws_id"]
      } else {
        return ["status"]
      }
    }
  }
}
</script>
